<template>

  <!-- id : contents : (S) -->
  <div id='contents' >
    <div class="sub-visual-wrap st-meeting">
      <div class="inner-base-box">
        <h2 class="h2">{{ menuName }}</h2>
        <ol>
          <li style="background: none;">홈</li>
          <li>{{ menuName }}</li>
          <li>{{ currentTabMenu.menuName }}</li>
        </ol>
      </div>
    </div>
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <div class="inner-base-box mb-pd-none">
        <div class="tab-menu-box">
          <a href="javascript:;" class="btn-menu " :class="{open : this.$store.state.tapOpen === true}" @click="openTab()">탭열기버튼</a>
          <span class="t-txt">MENU</span>
        </div>
        <div class="secPageTabBox " :class="{open : this.$store.state.tapOpen === true}">
          <div class="btn-go-back mb-only"><a href="#" @click.prevent="goback">이전페이지</a></div>
          <div class="sub-tab-menu-box st-member">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
          <div class="sub-tab-mb-title">
            <a href="javascript:;" v-for="(tab) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTabMobile()" :key="tab.boardId">{{ tab.menuName }} <i class="arrow"></i></a>
          </div>
          <div class="sub-tab-list-box" :class="{on:this.tablistOpen === true}">
            <a href="javascript:;" v-for="(tab, index) in tabs" :class="{on:currentTabMenu.menuName==tab.menuName}" @click="onClickTab(tab, index)" :key="tab.boardId">{{ tab.menuName }}</a>
          </div>
        </div>
      </div>
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="inner-base-box mb-pd-none">

          <div class="subpage-title-box" v-if="pageId!=null">
            <h3 class="h3">{{ currentTabMenu.menuName }}</h3>
          </div>
          <div class="subpage-title-box" v-else-if="tabIndex!=null">
            <div class="tit-tab-box">
              <router-link :to="`/meeting/6?upper=${parentMenu}&menu=${currentTabMenu.menuId}`" :class="{on:tabIndex==6}">e-CLUB 소개</router-link>
              <router-link :to="`/meeting/7?upper=${parentMenu}&menu=${currentTabMenu.menuId}`" :class="{on:tabIndex==7}">회원가입 소개</router-link>
            </div>
          </div>

          <div v-if="tabIndex==null" v-html="page.contents"></div>
          <MeetingGuide v-else-if="tabIndex==6"></MeetingGuide>
          <MeetingGuideMember v-else-if="tabIndex==7"></MeetingGuideMember>

        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>
import MeetingGuideMember from '@/views/board/meeting/guide_eclub_member'
import MeetingGuide from '@/views/board/meeting/guide_eclub'
import menuApi from "@/api/MenuApi";
import pageApi from "@/api/PageApi";

export default {
  name: "MeetingIndex",
  components:{
    MeetingGuideMember,
    MeetingGuide
  },
  data(){
    return {
      pageId:this.$route.params.id,
      tabIndex:this.$route.params.tapNo,
      parentMenu:this.$route.query.upper,
      tabs:[],
      currentTabMenu: {},

      menuName:'',
      page:{},
      currentMenuId: this.$route.query.menu,
      tablistOpen:false,
    }
  },
  created() {
    this.getMenu();
  },
  watch:{
    $route(){
      this.pageId = this.$route.params.id
      this.tabIndex = this.$route.params.tapNo
      this.getMenu();
    }
  },
  methods:{
    getMenu(){
      menuApi.getMenu(this.parentMenu).then(data=>{
        this.menuName=data.menuName
        this.tabs = data.childMenus;    //인사말, 활동소개, 조직도 etc
        if(this.pageId != null) {
          this.currentTabMenu = this.tabs.find(data => data.pageId == this.pageId)
        }else if(this.tabIndex != null){
          this.currentTabMenu = this.tabs[6]
        }

        this.getPageInfo();
      })
    },
    getPageInfo(){
      pageApi.get(this.pageId).then(data=>{
        this.page = data;
        //this.page = this.page.filter(d => d.activated);
      })
    },
    onClickTab(tab){
      //this.currentTabMenu = tab
      let url = tab.url;
      if(tab.menuType === 'TYPE_PAGE') {
        url = `/page/${tab.pageId}`;
      }
      this.$router.push(url+`?upper=${this.parentMenu}&menu=${tab.menuId}`).catch(()=>{});
    },
    onClick(val){
      switch (val){
        case 1:
          alert('aa')
          this.$router.push('/board/567?upper=431&menu=489');
          break;
        case 2:
          this.$router.push('/group/schedule?upper=431&menu=488');
          break;
        case 3:
          this.$router.push('/board/214?upper=428&menu=461');
          break;
        case 4:
          this.$router.push('/board/215?upper=427&menu=442');
          break;
      }
    },
    /*탭 오픈*/
    openTab(){
      this.$store.commit('setTapOpen')
    },
    onClickTabMobile(){
      if(this.tablistOpen){
        this.tablistOpen = false;
      }else{
        this.tablistOpen = true;
      }
    },
    goback(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>

</style>