<template>

  <!-- id : contents : (S) -->
  <div id='contents' >

    <!-- activit-top-box (S) -->
    <div class="activit-top-box st-guide-eclub">
      <div class="inner-base-box">

        <!-- activit-btm-box (S) -->
        <div class="activit-btm-box" style="margin-bottom: 90px;">
          <div class="inner-base-box">
            <div class="tit-box">전자사랑 회원 혜택</div>
            <!-- 2021-03-08 add -->
            <div class="row-box">
              <dl>
                <dt>경조사 지원 <a href="javascript:;" class="btn-link" @click="onClick(1)">경조사 지원</a></dt>
                <dd><div>혼사(축하기/ 全 회원 이메일, 홈페이지 공지)<br></div>
                <div>조사(근조기/ 全 회원 이메일, 홈페이지 공지)<br></div></dd>
              </dl>
              <dl class="st2">
                <dt>행사 <a href="javascript:;" class="btn-link" @click="onClick(2)">행사</a></dt>
                <dd>행사 안내문 (月 1회), 오찬특강(月 1회)</dd>
                <dd>해외/국내 문화탐방(年 10회), 송년회, 각 동호회別 자체 행사 시행</dd>
              </dl>
            </div>
            <div class="row-box">
              <dl class="st3">
                <dt>동호회 지원 <a href="javascript:;" class="btn-link" @click="onClick(3)">동호회 지원</a></dt>
                <dd>동호회別 年 4회 인당 활동비 지원<br></dd>
                <dd>행사 시 버스 지원</dd>
              </dl>
              <dl class="st4">
                <dt>분과 지원 <a href="javascript:;" class="btn-link" @click="onClick(4)">분과 지원</a></dt>
                <dd>분과別 年 4회 인당 활동비 지원</dd>
                <dd>분과別 年 YB모임시 4회 인당 활동비 추가 지원</dd>
                <dd>분과別 신입회원 간담회 지원</dd>
                <dd>행사시 버스 지원</dd>
              </dl>
            </div>
            <div class="row-box">
              <dl class="st5">
                <dt>라운지 제공</dt>
                <dd>서초동 라운지 이용<br> OA기기 사용(PC, 프린터, 팩스)<br> 도서 대여<br> Tea, 다과 제공<br>공식 행사 참석 시 중식쿠폰 제공</dd>
              </dl>
              <dl class="st6">
                <dt>전자사랑몰</dt>
                <dd>회원 전용 삼성전자 제품 및 생활용품 할인 온라인몰<br></dd>
              </dl>
            </div>
            <!-- 2021-03-08 add -->
            <div class="row-box">
              <dl class="st7">
                <dt>건강검진센터 제휴</dt>
                <dd>회원 전용 건강검진 제휴 할인 서비스</dd>
              </dl>
              <dl class="st8">
                <dt>여행사 제휴</dt>
                <dd>회원 전용 국내외/골프 여행 할인 서비스  </dd>
              </dl>
            </div>
            <!-- 2024-08-28 add -->
          </div>
        </div>
        <!-- activit-btm-box (E) -->

        <!-- 2020-12-08 add -->
        <div class="activit-btm-box">
          <div class="inner-base-box">
            <div class="tit-box">전자사랑모임을 소개합니다</div>
          </div>
        </div>
        <!-- 2020-12-08 add -->
        <div class="data-box">
          <div class="img-box "></div>
          <div class="info-box">
            <ul>
              <li><span class="tit">발 족 일</span><p>99. 4.1</p></li>
              <li><span class="tit">구 성</span><p>회장 강호문, 부회장 윤주화, 부회장 조수인, <br>간사 조병학, 감사 이용희 外 1,040명</p></li>
              <li><span class="tit">회원자격</span><p>삼성전자에서 근무 이력이 있는 퇴임 임원<br>(단, 결격사유로 퇴임한 임원은 가입 제외)</p></li>
              <li><span class="tit">입 회 비</span><p>300,000원 (신입회원)</p></li>
              <li><span class="tit">연 회 비</span><p>100,000원 </p></li>
              <li><span class="tit">홈페이지</span><p>www.eclub.or.kr </p></li>
              <li><span class="tit">가입방법</span><p>PC/모바일을 통한 전자사랑모임 홈페이지 회원가입<br>자격 확인 → 회비 납부 → 회원 등록 </p></li>
              <li><span class="tit">연 락 처</span><p>
                TEL : 02-558-3401,3  /   FAX : 02-558-3404<br>
                사무실 운영시간 : 월~금 09:00~18:00 운영<br>
                E-MAIL : eclub@eclub.or.kr </p></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- activit-top-box (E) -->

    <!-- activit-mid-box (S) -->
    <div class="activit-mid-box">
      <div class="inner-base-box "><div class="tit-box">주요활동</div></div>
      <div class="inner-base-box st-cont">
        <dl>
          <dt>다양한 행사</dt>
          <dd>정기총회 및 송년모임, 오찬특강, <br>교양특강</dd>
        </dl>
        <dl>
          <dt>동호회 활동</dt>
          <dd>문화탐방, 골프, 당구, 등산, 문인화, <br>바둑, 사진, 서예, 음악</dd>
        </dl>
        <dl>
          <dt>분과 활동</dt>
          <dd>CE, 디스플레이, 반도체, IT, 연구개발, 한국총괄,<br>
            지원, 통신, 해외마케팅, 美서부지역</dd>
        </dl>
        <dl>
          <dt>전시회</dt>
          <dd>회원 작품전 전시회 (서예, 사진)</dd>
        </dl>
        <dl>
          <dt>사회공헌 활동</dt>
          <dd>나자로 봉사팀 , 부인 봉사팀</dd>
        </dl>
        <dl>
          <dt>경조사 안내</dt>
          <dd>회원 경조사 안내 및 지원</dd>
        </dl>
      </div>
    </div>
    <!-- activit-mid-box (E) -->


  </div>
  <!-- class : main-wrap (E) -->

</template>

<script>
export default {
name: "guide_eclub",

  methods:{
    onClick(val){
      switch (val){
        case 1:
          this.$router.push('/board/567?upper=431&menu=489');
          break;
        case 2:
          this.$router.push('/group/schedule?upper=431&menu=488');
          break;
        case 3:
          this.$router.push('/board/214?upper=428&menu=461');
          break;
        case 4:
          this.$router.push('/board/215?upper=427&menu=442');
          break;
      }
    }
  }
}
</script>

<style scoped>

</style>