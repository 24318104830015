<template>

  <!-- id : contents : (S) -->
  <div id='contents' style="margin-bottom: 100px">
    <!-- member-join-intro-top-box (S) -->
    <div class=" member-join-intro-top-box" >
      <div class="inner-base-box">
        <div class="inner-box">
          <dl>
            <dt>회원가입</dt>
            <dd>전자사랑모임 홈페이지,<br>어플 회원가입 진행</dd>
          </dl>
          <dl>
            <dt>가입 여부 검토 및 승인</dt>
            <dd>① 삼성전자 가입 승인 통보<br>(최대 1주 소요)<br>② 운영위원단 검토</dd>
          </dl>
          <dl>
            <dt>회비 납부</dt>
            <dd>① 입회/연회비 납부 요청</dd>
          </dl>
          <dl>
            <dt>가입 완료</dt>
            <dd>회비 납부 확인 후 <br>가입 완료 처리</dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- member-join-intro-top-box (E) -->
    <!-- member-join-intro-btm-box (S) -->
    <div class="member-join-intro-btm-box">
      <div class="inner-base-box">
        <ul>
          <li>* 전자사랑모임 홈페이지/어플 에서 회원가입 必</li>
          <li>* 운영위원회에서 검토 후 회원가입 승인함</li>
          <li>* 입회비, 연회비를 납부해야 회원으로 인정됨 (홈페이지/어플 본인이 직접 가입)</li>
        </ul>
      </div>
    </div>
    <!-- member-join-intro-btm-box (E) -->

  </div>
  <!-- class : main-wrap (E) -->

</template>

<script>
export default {
name: "guide_eclub"
}
</script>

<style scoped>

</style>